<template>
  <div class="container">
    <h4 class="text-center my-4">Преподаватели</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="my-4">
        <router-link to="/change-photo-administration">
          Администрация
        </router-link>
      </div>


      <div class="row">
        <div class="col-md-3">
          <select class="form-control form-select" @change="changeDepartments">
            <option v-for="(item, index) in [{id: 0, name_ru: 'Фильтрация по кафедрам Все'}, ...departments]"
                    :value="item.id"
                    :key="index"
                    :selected="item.id==departmentId">
              {{ item.name_ru }}
            </option>
          </select>
        </div>
      </div>


      <div class="my-4" v-if="pps.length">
        <DataTable :value="pps" showGridlines stripedRows responsiveLayout="scroll">
          <Column header="ФИО">
            <template #body="{data}">
              {{ data.lastname }} {{ data.firstname }} {{data.middlename}}
            </template>
          </Column>
          <Column header="Фото">
            <template #body="{data}">
              <div>
                <div v-if="data.beauty_photo" class="img_photo" :style="`background-image: url(https://back.uib.kz/uploads/${data.beauty_photo});`"></div>
                <div v-else>Нету фото</div>
              </div>
            </template>
          </Column>
          <Column header="Фото (ширина 225px, высота 300px)">
            <template #body="{data}">
              <div class="mt-2 row">
                <div class="col-md-6">
                  <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control"
                         @change="handleFileUpload($event, data.id)">
                </div>
                <div class="col-md-6">
                  <Button icon="pi pi-save" label="Сохранить" @click="save"/>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>

      </div>

    </div>


  </div>
</template>

<script>


import {mapActions, mapState} from 'vuex'


export default {
  data() {
    return {
      loading: true,
      departmentId: 0,
      ppsPhoto: {}
    }
  },
  computed: {
    ...mapState('vacancy', ['departments']),
    ...mapState('changePhoto', ['pps']),
  },
  methods: {
    ...mapActions('vacancy', ['GET_DEPARTMENTS']),
    ...mapActions('changePhoto', ['GET_PPS', 'POST_PHOTO']),
    async changeDepartments(e) {
      this.departmentId = e.target.value
      await this.GET_PPS(this.departmentId)
    },
    handleFileUpload(e, user_id) {
      this.ppsPhoto['user_id'] = user_id
      this.ppsPhoto['file'] = e.target.files[0]
    },
    async save() {
      const res = await this.POST_PHOTO(this.ppsPhoto)
      if (res) {
        this.$message({title: 'Сохранено'})
        await this.GET_PPS(this.departmentId)
      }
    }
  },
  async mounted() {
    await this.GET_DEPARTMENTS()
    this.loading = false
  },
}

</script>

<style scoped>
.img_photo{
  width: 225px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
